@charset "utf-8";
/* CSS Document */

@font-face {
	font-family: "Comfortaa";
	src:  url(/static/media/Comfortaa-Light.866333be.ttf) format("truetype");
}

html {
	scroll-behavior: smooth;
}

body {
	font-family: "Comfortaa";
}

.General hr {
  width: 100%;
  border: none;
  height: 2px;
  background-color: white;
	margin-top: 5px;
	margin-bottom: 10px;
  background-image: -webkit-linear-gradient(left, rgba(0,0,0,0), #a73972, rgba(0,0,0,0));
}

/* .back {
	background-image: url("/img/sfondo.jpg");
} */

/* SCROLL */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a73972;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ef5aa7;
}

.Register .navbar {
	background-color: #a73972;
	color: white;
}

/* MENU INTERNAL */
.Married .navbar {
	font-family: "Comfortaa";
	background-color: rgba(167, 57, 114, 1);
}

.Married .nav-item {
	width: 100px;
	font-size: 13px;
	text-align: center;
	padding-top: 20px;
  padding-bottom: 20px;
	cursor: pointer;
}

.Married .section-text {
	position: absolute;
  left: 100px;
  top: 15px;
}

.Married .section-text small {
	color: white !important;
}

.Married .active {
	background-color: #d77eac;
}

/* FINE MENU INTERNAL */

/* MENU MOBILE */
.Married .navbar-toggler {
	display: none;
  cursor: pointer;
	border: 0 !important;
	background-color: #a73972 !important;
}

.Married .bar1, .Married .bar2, .Married .bar3 {
  width: 35px;
  height: 5px;
  background-color: rgba(215, 126, 172, 0.6);
  margin: 6px 0;
  transition: 0.4s;
}

.Married .change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
  background-color: rgba(215, 126, 172, 1);
}

.Married .change .bar2 {opacity: 0;}

.Married .change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
  background-color: rgba(215, 126, 172, 1);
}

.Married nav.open {
	position: fixed !important;
	height: 100vh;
  overflow: auto;
  padding-bottom: 70px;
}

/********************************/

.Married .imageGeneral {
	width: 250px;
	height: 100px;
	margin: 0 auto;
	border-radius: 6px;
	background-position: center;
	background-size: cover;
}

.my-progress-bar {
  background-color: #d77eac;
  border-radius: 1.25em;
  width: 100%;
  height: 20px;
  display: inline-block;
	margin-top: 40px;
}

.progress-value {
  background-color: #a73972;
  transition: 0.3s all linear;
  border-radius: 1.25em;
  height: 14px;
  display: inline-block;
}

/****************************/

.margin-bottom-0 {
	margin-bottom: 0;
}

.margin-top-0 {
	margin-top: 0 !important;
}

#login {
	font-family: "Comfortaa";
	color: #a73972;
	padding-bottom: 20px;
	padding-top: 5vh;
	background-color: #a73972;
	height: 100vh;
}

#login img {
	padding-top: 20px;
}

#error-login {
	text-align: center;
	color: rgb(239, 90, 167);
	display: none;
	font-size: 18px;
}

#error-login small {
	color: white;
}

#login .form-group small a {
	color: white !important;
}

.code-password {
	font-size: 21px;
}

a {
	color: inherit !important;
}

a:hover {
	text-decoration: none !important;
	color: white !important;
}

input {
	background-color: transparent;
	border: 0;
	border-bottom: 2px solid white;
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: white;
	background-clip: padding-box;
}

select option {
	color: black;
}

input:focus, input:active,
select:focus, select:active {
	outline: 0;
}

.center-single-btn {
	width: 250px !important;
	margin: 0 auto !important;
	text-align: center;
}

.btn-submit {
	background-color: #a73972;
	border: 2px solid white;
	color: white;
	padding: 10px;
	border-radius: 6px;
	width: 210px;
	margin: 0 auto;
  transition: 0.3s;
}

.btn-submit { transition: all .2s ease-in-out; }
.btn-submit:hover {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
	box-shadow:         0px 13px 15px 0px rgba(50, 50, 50, 0.75);
}

/* REGISTER */
#register {
	font-family: "Comfortaa";
	color: #a73972;
}

#register .legal-link {
	color: #d77eac !important;
	margin-left: 5px;
	font-weight: bold;
}

#register .legal-link:hover {
	color: #a73972 !important;
}

label, small, h1, p, .row {
	color: white;
}

#title, .section {
	font-family: "Comfortaa";
}

.nav-cta {
	position: fixed;
	bottom: 0;
	background-color: #a73972;
	color: white;
	z-index: 1029;
	padding: 10px;
}

.nav-cta .btn-login, .nav-cta .center-single-btn {
	margin-top: 24px;
	margin-bottom: 20px;
}

#register {
	padding-top: 120px;
	padding-bottom: 20px;
}

h2 {
	width: 95%;
}

.row {
	width: 100%;
}

.no-border-bottom {
	border-bottom: 0 !important;
}

.row-content {
	background-repeat: no-repeat;
	border-radius: 6px;
	min-height: 30px;
	padding: 20px;
}

.row-content {
		background-size: 10%;
		background-position: right top;
}

.row-content .form-group label {
	color: #a73972;
}

.row-content .form-group input,
.row-content .form-group textarea {
	color: black;
	border-bottom: 2px solid #a73972;
}

.row-content .form-group select {
	width: 100%;
	border: 0;
	color: black;
	border-bottom: 2px solid #a73972;
	padding: 8px;
}

.General .col-lg-6 .row-content {
	width: 90%;
}

.General .waitSave {
	width: 100%;
	padding: 20px;
	background-color: #a73972;
	border: 2px solid white;
	border-radius: 6px;
}

.General .card {
	width: 100% !important;
	border-radius: 6px;
	border-color: #a73972;
}

.title-section {
	width: 100%;
}

.title-section p {
	text-align: center;
	font-size: 24px;
	color: #a73972;
}

.content-elms {
	width: 100%;
}

.content-elms .row {
	border-bottom: 1px solid rgba(215, 126, 172, 0.6);
	font-size: 18px;
	color: #a73972;
}

.content-elms .row .col-lg-1 svg,
.content-elms .row .col-2 svg,
.content-elms .row .col-lg-2 svg,
.content-elms .row .col-6 svg,
.button-chat-users, .bml-icon svg {
	cursor: pointer;
	height: 35px;
  width: 35px !important;
	padding: 8px;
  background-color: #d77eac;
  border-radius: 50%;
  display: inline-block;
}

.content-elms .row div {
	padding: 15px;
}

.margin-top-50 {
	margin-top: 50%;
}

.drag {
	cursor: -webkit-grab;
	cursor: grab;
	color: white;
  text-align: center;
  margin-top: 40px;
}

.content-elms .margin-icons {
	margin: 0 5px;
}

 .content-elms .col-2 {
	cursor: pointer
}

.btn-edit {
	cursor: pointer;
	background-color: #d77eac;
	border: 2px solid white;
	color: white;
	padding: 10px;
	border-radius: 6px;
	width: 250px !important;
	margin: 0 auto !important;
}

.btn-edit-small {
	margin-left: 30px;
	font-size: 12px;
}

small {
	color: #a73972;
}

textarea {
	width: 100%;
  background-color: transparent;
  border: 0;
	border-bottom: 2px solid white;
	height: 150px;
	color: white;
	resize: none !important;
}

textarea:focus, .btn-submit:focus, .btn:focus {
	outline: none !important;
	box-shadow: 0;
}

#register_ok .row-content,
#register_ko .row-content,
#register_ok .row-content p,
#register_ko .row-content p {
	color: black !important;
	padding: 20px !important;
}

p {
	width: 100%;
}

.my-ring {
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
  margin-left: -5px;
}

.margin-top-5 {
	margin-top: 5px;
}

.row {
	padding: 0 !important;
	margin: 0 !important;
}

/* MARRIED */
.btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem blue !important;
}

button:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.btn-mobile {
  width: 100%;
  text-align: center;
  color: white !important;
  border-bottom: 1px solid white !important;
}

.button-play {
	padding-top: 4px;
  padding-left: 7px;
	font-size: 22px !important;
	color: white !important;
	margin-left: 10px !important;
}

.button-play .tooltiptext {
	left: 15px !important;
	top: -33px !important;
	font-size: 12px;
}

.button-submit {
	width: 100px !important;
	height: 100px;
	border-radius: 50%;
	background-color: #a73972;
	padding-top: 24px;
	position: fixed !important;
	bottom: 20%;
	right: 20px;
}

.button-submit button {
	background-color: #a73972;
	border: 0;
	color: white;
	padding: 0;
	width: 45px;
	margin: 0 auto;
}

.button-submit button:active,
.button-submit button:focus {
	outline: none;
}

/* LIST MARRIED */
.size-l {
  width: 220px;
}

.List .row-content,
.Program .row-content {
  margin-bottom: 10px;
}

.Married p {
	color: #a73972;
}

.Married .justify-content-center {
	color: #a73972;
}

/* GUEST */
.modal {
   position: fixed;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(215, 126, 172, 0.5);
   opacity: 0;
   visibility: hidden;
   -webkit-transform: scale(1.1);
           transform: scale(1.1);
   transition: visibility 0s linear 0.25s, opacity 0.25s 0s, -webkit-transform 0.25s;
   transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
   transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s, -webkit-transform 0.25s;
}
.modal-content {
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
	 background-color: rgb(167, 57, 114) !important;
   padding: 1rem 1.5rem;
   width: 80% !important;
   border-radius: 0.5rem;
	 margin-top: 60px;
}
.close-button {
	cursor: pointer;
	height: 40px;
  width: 40px;
  background-color: rgb(215, 126, 172);
  border-radius: 50%;
  display: inline-block;
	color: white;
	text-align: center;
	font-size: 25px;
}
.close-button:hover {
   background-color: #ef5aa7;
}
.show-modal {
   opacity: 1;
   visibility: visible;
   -webkit-transform: scale(1.0);
           transform: scale(1.0);
   transition: visibility 0s linear 0s, opacity 0.25s 0s, -webkit-transform 0.25s;
   transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
   transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s, -webkit-transform 0.25s;
}

.modal-pay, .modal-card {
	color: white;
	font-family: "Comfortaa";
}

.modal-pay li, .modal-card li {
	list-style: none;
}

.modal-pay .modal-content, .modal-card .modal-content {
	background-repeat: no-repeat;
	background-position: right top;
	background-size: 10%;
}

.modal-pay .modal-content a:hover, .modal-content .modal-content a:hover {
	font-weight: bold;
}

.submit-pay {
	width: 150px;
	height: 133px;
}

.Guest .content-elms,
.Guest p {
  color: #a73972;
	font-size: 20px;
	margin-bottom: 20px !important;
}

.Guest .show-add-guest {
	background-color: #a73972;
  padding: 15px;
  border-radius: 6px;
  position: absolute;
	top: 85px;
	width: 90%;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
}

.Guest .show-add-guest .titleModalGuestList {
	color: white;
}

.color-small-guest {
	color: white;
}

.Message p,
.button-car {
	color: white !important;
}

.Message .row-content {
	padding: 0;
}

.Message .row-content p,
.Message .row-content label {
	padding: 20px;
}

.modal-message,
.justify-content-center,
.col-lg-1 {
  color: white;
}

.legal-link-guest {
	color: white !important;
	margin-left: 5px;
}

.legal-link-guest:hover {
	color: #d77eac !important;
}

/* GENERAL INVITED */
.General .general-white div {
	color: white;
}

.tooltip {
  position: relative !important;
  display: inline-block !important;
	opacity: 1 !important;
	z-index: 0 !important;
	cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: 47%;
  margin-left: -60px;
	top: -12px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover .tooltiptext::after {
	content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.text-sign {
	font-size: 35px;
}

.size-50 {
	font-size: 50px;
}

.InvitedList .row {
	margin: 20px 0 !important;
  background-color: white;
	color: #a73972;
  border-radius: 6px;
}

.InvitedList .tooltip {
	color: white;
}

.InvitedList form .row {
	background-color: #a73972 !important;
}

.ConfirmInvite {
	background-color: #a73972;
	height: 100vh;
}

.strike {
	opacity: 0.6;
}

.justify-content-center,
.justify-content-start {
	font-size: 24px;
}

.InvitedMenu h5 {
	padding: 15px;
}

.invited-color {
	color: #a73972 !important;
}

/* .budget-content {
	color: white;
	margin-bottom: 20px;
  background-color: #a73972;
  padding: 20px;
} */

.budget-content h4,
.budget-content label {
	font-size: 20px;
	color: #a73972;
}

.content-budget-father {
	border: 1px solid #a73972;
	border-radius: 6px;
}

.budget-border-left {
	-webkit-border-top-left-radius: 6px;
	-webkit-border-bottom-left-radius: 6px;
	-moz-border-radius-topleft: 6px;
	-moz-border-radius-bottomleft: 6px;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

.budget-border-right {
	-webkit-border-top-right-radius: 6px;
	-webkit-border-bottom-right-radius: 6px;
	-moz-border-radius-topright: 6px;
	-moz-border-radius-bottomright: 6px;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

.budget-content p,
.budget-content input {
	font-size: 28px;
}

.List hr,
.Program hr {
 width: 100%;
 border: none;
 height: 2px;
 background-color: white;
 background-image: -webkit-linear-gradient(left, rgba(0,0,0,0), #a73972, rgba(0,0,0,0));
}

#results {
	width: 90%;
	margin: 0 auto;
	border-radius: 6px;
	padding-top: 20px;
	background-color: rgba(215, 126, 172, 0.9);
	position: fixed;
	top: 110px;
	left: 5%;
  -webkit-animation: visibility 1.5s; /* Safari 4.0 - 8.0 */
  -webkit-animation-direction: normal; /* Safari 4.0 - 8.0 */
  animation: visibility 1.5s;
  animation-direction: normal;
	z-index: 9999;
}

#results .content-logo {
	width: 250px;
	margin: 0 auto;
	margin-top: 150px;
}

#results p {
	width: 100%;
	color: white;
	font-size: 21px;
	font-family: "Comfortaa";
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes visibility {
	0%   {top: 110px;}
  100% {top: 0;}
}

@keyframes visibility {
	0%   {top: 0px;}
  100% {top: 110px;}
}

/* CHAT */
#chat {
	border: 2px solid #a73972;
	resize: none;
	overflow: auto;
}
.view-chat {
	height: 70vh;
	overflow-y: scroll;
	overflow-x: hidden;
}

#view-chat-last {
	min-height: 50px;
}

.speech-bubble-1 {
	position: relative;
	background: rgba(215, 126, 172, 1);
	border-radius: .4em;
	padding: 20px;
	width: 80%;
	margin-left: 15%;
	margin-bottom: 20px;
	text-align: right;
	color: white;
}

.speech-bubble-1:after {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 24px solid transparent;
	border-left-color: rgba(215, 126, 172, 1);
	border-right: 0;
	border-bottom: 0;
	margin-top: -12px;
	margin-right: -24px;
}

.speech-bubble-2 {
	position: relative;
	background: rgba(215, 126, 172, 1);
	border-radius: .4em;
	padding: 20px;
	margin-left: 10%;
	margin-bottom: 20px;
	width: 80%;
	color: white;
}

.speech-bubble-2 p p {
	text-align: right;
}

.speech-bubble-2:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 24px solid transparent;
	border-right-color: rgba(215, 126, 172, 1);
	border-left: 0;
	border-top: 0;
	margin-top: -12px;
	margin-left: -24px;
}

.chat-img {
	border-radius: 6px;
	margin-top: 20px;
	box-shadow:         0px 13px 15px 0px rgba(50, 50, 50, 0.75);
	cursor: pointer;
}

.img-modal {
	height: 80vh;
}

.content-img-modal {
	width: 100vh;
	margin: 0 auto;
}

.chat-modal-loading {
	position: absolute;
	top: 150px;
  z-index: 10;
}

.view-chat p {
	padding: 20px;
}

.button-chat {
	color: white;
	font-size: 24px;
	position: absolute;
	bottom: 30px;
	right: 18px;
	cursor: pointer;
	width: 50px !important;
	height: 30px;
	background-color: white;
	color: #a73972;
}

.button-chat-users.tooltip .tooltiptext {
	top: -70px;
}

.button-image {
	color: #a73972;
	font-size: 34px;
}

.Message p.big-image-chat {
	color: #a73972 !important;
}

.inputfile {
	width: 34px;
	height: 34px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	left: 170px;
	cursor: pointer;
}

.padding-top-5 {
	padding-top: 5px !important;
}

.padding-top-10 {
	padding-top: 10px !important;
}

.padding-top-20 {
	padding-top: 20px !important;
}

.padding-bottom-10 {
	padding-bottom: 10px !important;
}

.view-chat::-webkit-scrollbar,
#chat::-webkit-scrollbar,
#list-menu-site::-webkit-scrollbar,
#nav-site::-webkit-scrollbar {
  width: 0;
}

.border-bottom-color {
	border-bottom: 2px solid #a73972;
}

/* Loading Modal Chat */
.chat-modal-loading-dot {
	width: 64px;
	margin: 0 auto;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0);
  }
}


/* HOMELOG */

.imageWedding {
	background-position: center;
	background-size: cover;
	/* padding: 20px; */
	background-color: rgba(167, 57, 114, 1);
	min-height: 75vh;
	max-height: 75vh;
	border-radius: 6px;
	color: rgba(167, 57, 114, 1);
	position: relative;
}

.imageWedding h4 {
	/* background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%); */
	background-image: linear-gradient(to bottom, #a73972 0%, rgba(215,126,172, 0.6) 100%);
	color: white;
	text-align: center;
	font-size: 20px;
	padding: 20px;
	-webkit-border-top-left-radius: 6px;
	-webkit-border-top-right-radius: 6px;
	-moz-border-radius-topleft: 6px;
	-moz-border-radius-topright: 6px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

.imageWeddingMobile {
	height: 100vh;
	max-height: 100vh;
	padding-top: 60px;
}

.bottonCover {
	width: 100%;
	position: absolute;
	bottom: 0;
	background-image: linear-gradient(to bottom, rgba(215,126,172, 0.6) 0%, #a73972 100% );
	color: white;
	text-align: center;
	font-size: 20px;
	padding: 20px;
	-webkit-border-bottom-right-radius: 6px;
	-webkit-border-bottom-left-radius: 6px;
	-moz-border-radius-bottomright: 6px;
	-moz-border-radius-bottomleft: 6px;
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
}

.imageWedding small,
.imageWedding h5 {
	/* text-shadow: 0px 0px 6px #fff; */
	color: #a73972;
}

.imageWedding time {
	color: white;
}

.heartHome {
	color: #a73972;
}

.bml-icon-large svg {
	cursor: pointer;
	height: 50px;
	width: 50px !important;
	padding: 10px;
	background-color: #d77eac;
	border-radius: 50%;
	display: inline-block;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
}

/* FIME HOMELOG */


/* HOME PAGE */

#navHome li {
	cursor: pointer;
	min-width: 100%;
}

#home #fullpage {
	background-color: #d6d3d3;
}

#section0 p {
	font-family: "Comfortaa";
	font-size: 2em;
	margin: 0;
}

#home h1, #section4 .footer {
	font-family: "Comfortaa";
	font-size: 4em;
	margin: 0;
	font-weight: normal;
	color: white;
}

#home .help-scroll {
	position: absolute;
	bottom: 100px;
	left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
	font-size: 0.8em;
	z-index: 999;
	cursor: pointer;
}

#home .mouse {
	position: absolute;
	width: 22px;
	height: 42px;
	bottom: 120px;
	left: 50%;
	margin-left: -12px;
	border-radius: 15px;
	border: 2px solid white;
	-webkit-animation: intro 1s;
	        animation: intro 1s;
	z-index: 999;
	cursor: pointer;
}

#home .scroll {
	display: block;
	width: 3px;
	height: 3px;
	margin: 6px auto;
	border-radius: 4px;
	background: white;
	-webkit-animation: finger 1s infinite;
	        animation: finger 1s infinite;
}

@-webkit-keyframes intro {
	0% {
		opacity: 0;
		-webkit-transform: translateY(40px);
		        transform: translateY(40px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes intro {
	0% {
		opacity: 0;
		-webkit-transform: translateY(40px);
		        transform: translateY(40px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@-webkit-keyframes finger {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		-webkit-transform: translateY(20px);
		        transform: translateY(20px);
	}
}

@keyframes finger {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		-webkit-transform: translateY(20px);
		        transform: translateY(20px);
	}
}

/* .ball {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #FF5722;
	float: right;

  animation: bounce 2s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(.5,0.05,1,.5);
  animation-iteration-count: infinite;
}

@keyframes bounce {
  from { transform: translate3d(0, 0, 0);     }
  to   { transform: translate3d(0, 400px, 0); }
} */

/* Prefix Support */
/* .ball {
  -webkit-animation-name: bounce;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: cubic-bezier(.5,0.05,1,.5);
  -webkit-animation-iteration-count: infinite;
} */

/* @keyframes bgcolor {
    0% {
        background-color: #a73972
    }
    100% {
        background-color: #d77eac
    }
}

#home .content-tags {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
}

#home .content-tags .first-elm {
	width: 20%;
	font-weight: bold;
	margin: 20px;
	cursor: pointer;
	margin-top: 15px;
	padding-top: 5px;
	margin-bottom: 15px;
	padding-bottom: 5px;
	text-align: center;
	font-size: 1.1em;
	min-height: 455px;
}

#home .content-tags .first-elm div {
	background-color: #a73972;
	color: white;
	margin-bottom: 10px;
	padding: 5px;
	position: relative;
	border-radius: 4px;
}

#home .content-tags .first-elm div.active::after {
	left: 100%;
	top: 60%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-left-color: #d77eac;
	border-width: 25px;
	margin-top: -30px;
}

#home .content-tags .read-tags {
	width: 70%;
	font-size: 1.4em;
}

#home .content-tags .read-tags p {
	color: black;
}

#home .content-tags .first-elm div:hover,
#home .content-tags .first-elm div.active {
	background-color: #d77eac;
}

#home .content-tags .first-elm div.active {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
} */

/* #home .content-tags .first-elm div:hover hr,
#home .content-tags .first-elm div.active hr {
	background-image: -webkit-linear-gradient(left, rgba(0,0,0,0), #d77eac, rgba(0,0,0,0));
} */

/* #home .content-tags hr {
	margin: 0
} */

/* #home .content-tags .read-tags {
	min-height: 455px;
	max-height: 455px;
	overflow: auto;
}

#home .content-tags .read-tags span,
#home .content-tags .read-tags span:hover {
	padding: 10px;
	color: inherit;
	font-weight: normal;
	cursor: auto;
	color: black !important;
}
*/

#section4 {
	padding-bottom: 40px;
	background-color: #a73972;
}

#section4 .footer {
	width: 100%;
	height: 60px;
	min-height: 50px;
	text-align: center;
	font-size: 35px;
}

#section4 .row-action-footer div:first-child,
#section4 .row-action-footer div:last-child {
	padding-top: 5px;
}

#home .white {
	padding-top: 20vh;
  color: #fff;
}
/*
#section4 .white {
	padding-top: 20px;
	color: #fff;
}

#home .black {
  color: #000;
}
*/
#home .section {
	min-height: 100vh;
  text-align: center;
}
/*
#home .section .list {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	background-color: #a73972;
	color: white;
	font-size: 0.45em;
	margin: 0 auto;
	-webkit-animation: bgcolor 20s infinite;
	animation: bgcolor 10s infinite;
	-webkit-animation-direction: alternate;
	animation-direction: alternate;
}

#home .section .rotate-animate {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: white;
	position: relative;
	left: auto;
	right: auto;
	margin-left: 50%;
	margin-right: 50px;
	top: -85px;
	left: -10px;
	animation: circle 3s linear infinite;
}

@keyframes circle {
  0%{
    transform: rotate(0deg) translate(-60px) rotate(0deg);

  }
  100%{
    transform: rotate(360deg) translate(-60px) rotate(-360deg);
  }
}

#home .icon-custom-1 {
  font-size: 250px;
}

#home .section h5 {
	color: black;
	font-size: 0.25em;
}

#home .icon-custom-2 {
  font-size: 250px;
  color: white;
}

#home .flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
	padding: 0px 10px;
}

#home .flex-item:nth-child(1) {
  width: 40vw;
}

#home .flex-item:nth-child(2) {
  width: 60vw;
}

#home .text {
  padding: 20px;
	text-align: left;
	color: #a73972;
	border-left: 2px solid;
}

#home .text h2 {
  font-size: 35px;
}

#home .text p {
  font-size: 1em;
}

#home .text ul {
	padding: 0 50px;
	text-align: left;
	list-style: none;
}

#home .text li {
	font-size: 20px;
	margin: 10px 0;
}

#home .height-320 {
	min-height: 320px !important;
}

#home .cta-one {
	font-family: "Comfortaa";
	text-align: center;
}

.set-animate {
	animation: entryBox 0.5s linear;
	opacity: 1 !important;
}

@keyframes entryBox {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

#home .cta-one h5 {
	font-size: 1.8em;
	padding-top: 10px;
	font-weight: bold;
}

#home .cta-one p {
	font-size: 1.6em;
	color: black;
	padding-top: 10px;
}
*/

#home .content-minilove {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	color: black;
	font-family: "Comfortaa";
}

#home .single-col-minilove {
	width: 200px;
	margin: 0 auto;
}

#home .single-col-minilove-text {
	width: 60%;
}

#home .single-col-minilove-text p {
	color: black;
	font-size: 1.2em;
  text-align: center;
}

#section0 {
	background-color: #a73972 !important;
}

/*

#section0, #register .card {
	background-color: #a73972 !important;
}

#section1, #bemylove, #section3, #section5, #section6, #section6 {
	opacity: 0;
}
*/
#home .cta-point-2 {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	margin: 0 auto;
	width: 100%;
}

#home .cta-point-2 a {
	width: 30%;
}

#home .flex-item.text svg,
.Site .interested svg {
	color: #a73972;
}

/* */

@-webkit-keyframes move_wave {
    0% {
        -webkit-transform: translateX(0) translateZ(0) scaleY(1);
                transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
                transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }
    100% {
        -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
                transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}

@keyframes move_wave {
    0% {
        -webkit-transform: translateX(0) translateZ(0) scaleY(1);
                transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
                transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }
    100% {
        -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
                transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}

.waveWrapper {
    overflow: hidden;
    position: absolute;
		height: 200px;
    left: 0;
    right: 0;
    bottom: 0;
    /*top: 0;*/
    margin: auto;
}

.waveWrapperInner {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 100%;
    bottom: -1px;
    /* background-image: linear-gradient(to top, #86377b 20%, #27273c 80%); */
}

.bgTop {
    z-index: 15;
    opacity: 0.5;
}

.bgMiddle {
    z-index: 10;
    opacity: 0.75;
}

.bgBottom {
    z-index: 5;
}

.wave {
    position: absolute;
    left: 0;
    width: 200%;
    height: 100%;
    background-repeat: repeat no-repeat;
    background-position: 0 bottom;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
}

.waveTop {
    background-size: 50% 100px;
}

.waveAnimation .waveTop {
  animation: move-wave 3s;
   -webkit-animation: move-wave 3s;
   -webkit-animation-delay: 1s;
   animation-delay: 1s;
}

.waveMiddle {
    background-size: 50% 120px;
}

.waveAnimation .waveMiddle {
    -webkit-animation: move_wave 10s linear infinite;
            animation: move_wave 10s linear infinite;
}

.waveBottom {
    background-size: 50% 100px;
}

.waveAnimation .waveBottom {
    -webkit-animation: move_wave 15s linear infinite;
            animation: move_wave 15s linear infinite;
}

#home section {
	width: 100%;
	min-height: 100vh;
	padding-top: 80px;
	padding-bottom: 20px;
	overflow: hidden;
	background-color: #d6d3d3 !important;
	font-family: "Comfortaa";
	/* background-repeat: no-repeat;
	background-size: 22%; */
}

#home .section-1 {
	/* background-image: url("/img/mobile-login-w.png");
	background-position: 75vw 10vh; */
}

#home .content-section-1 {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	width: 100%;
}

#home .content-section-1 .col-1 {
	min-width: 70%;
	max-width: 70%;
}

#home .content-section-1 .col-2 {
	min-width: 20%;
}

#home .section-2 {
	background-position: center bottom;
	background-size: inherit;
	background-color: white !important;
	/* animation: changeBg 6s steps(2) infinite; */
}

#home .content-trittico {
	width: 650px;
	margin: 0 auto;
}

#home .content-trittico img  {
	width: 650px;
}

/* #home .section-2.trittico {
	background-image: url("/img/trittico.png");
} */

/* #home .section-2.cards {
	background-image: url("/img/cards.jpg");
} */

/* @keyframes changeBg {
  from, 33% {
      background-image: url("/img/mobile-w.png");
			background-size: 22%;
  }
  34%, 66% {
      background-image: url("/img/tablet-w.png");
			background-size: 27%;
  }
  67%, to {
      background-image: url("/img/frigorifero.png");
			background-size: 22%;
  }
} */

#home .content-title {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

#home .section-2 .content-title {
	margin: 0 auto;
	text-align: center;
}

/* #home .content-title .box-number {
	width: 65px;
	height: 65px;
	border-radius: 50%;
	background-color: #a73972;
	color: white;
	font-size: 30px;
	text-align: center;
	padding-top: 12px;
	margin-left: 10vw;
} */

#home .content-title .box-text {
	padding: 10px;
	width: 20vw;
	padding-left: 30px;
	width: 100%;
}

#home .content-title .box-text h2 {
	font-weight: normal;
	padding-top: 6px;
	text-align: center;
}

#home hr.gray {
  width: 100%;
  border: none;
  height: 1px;
  background-color: #d6d3d3;
  background-image: -webkit-linear-gradient(left, #d6d3d3, #a73972, #d6d3d3);
}

#home hr.whitehr {
  width: 100%;
  border: none;
  height: 1px;
	background-color: white;
	background-image: -webkit-linear-gradient(left, white, #a73972, white);
}

#home .content-text {
	color: black;
	padding: 20px;
	font-size: 1.2em;
	text-align: center;
}

#home .single-btn {
	width: 220px;
	margin: 0 auto;
	text-align: center;
}

#home .section-3 h2 {
	padding-left: 40px;
}

#home .section-3 {
	background-color: white !important;
}

#home .content-how {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	width: 96vw;
}

#home .list-tags {
	width: 20vw;
	text-align: center;
	cursor: pointer;
}

#home .list-tags div {
	margin-bottom: 5px;
}

#home .read-tags {
	width: 50vw;
	padding: 20px;
	margin: 0 auto;
}

#home .read-tags p,
.content-img-general div {
	color: black;
}

#home .read-tags div {
	padding: 10px;
	cursor: pointer;
}

#home .button,
#section4 .button,
.button {
	padding: 10px;
	border-radius: 2px;
	background-color: #a73972;
	color: white;
	width: 90%;
	opacity: 1;
	transition: 0.3s;
	margin: 20px;
	border: 0;
	cursor: pointer;
}

#home .button:focus,
#section4 .button:focus,
.button:focus {
	outline: 0;
}

#home .button:hover,
#section4 .button:hover,
#home .list-tags .active,
.button:hover {
	opacity: 0.6;
}

.button-hover-submit:hover,
.button-hover-submit:hover button {
		background-color: #d77eac;
		opacity: 1;
}

#section4 .button {
	background-color: rgb(239, 90, 167);
}

#home .single-btn {
	width: 50%;
	margin: 0 auto;
}

#home .box-text {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

#home .box-text div {
	width: 50%;
	text-align: center;
}

#home .box-text div p:first-child {
	font-size: 32px;
  line-height: 0;
	color: black;
}

#home .box-text small {
	color: black;
	font-size: 12px;
	margin-top: -20px;
}

#home .box-text div:first-child {
	text-align: left;
	padding-left: 25px;
}

#home .box-text div:last-child {
	text-align: right;
	padding-right: 25px;
}

#home .section-2.cards .content-text + div {
	width: 100%;
}

#home .section-2.cards .PlayCard + div {
	display: none;
}

#home .content-mobile {
	width: 250px;
	margin: 0 auto;
	margin-bottom: 20px;
}

/* */

.Site iframe {
	width: 100%;
}

.Site .bg-logo {
	background-position: center bottom;
	background-repeat: no-repeat;
}

.Site .interested {
	border-top: 1px solid #a73972;
}

.Site .interested h3 {
	color: #a73972;
}

.Site ul li svg {
	margin-right: 10px;
}

.Site .content-img {
	width: 250px;
	margin: 0 auto;
}

.Site .sabrina {
	margin-top: 50px;
  border-radius: 50%;
	box-shadow:         0px 0px 14px 0px rgba(50, 50, 50, 0.75);
}

.Site .author {
	border-radius: 50%;
}

/* Countdown */
.countdown-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
		margin: 0 auto;
}

.countdown-item {
    color: white;
    font-size: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    line-height: 30px;
    margin: 10px;
    padding-top: 10px;
    position: relative;
    width: 100px;
    height: 100px;
}

.countdown-item span {
    color: white;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.countdown-svg {
	z-index: 1;
	position: absolute;
	top: 3px;
}

.content-final-countdown .content-img-countdown {
	width: 300px;
	margin: 0 auto;
}

.content-final-countdown .content-img-countdown img {
	width: 100px;
}

.row-count {
	width: 100%;
	background-color: rgba(167, 57, 114, 0.6);
	position: absolute;
	bottom: 150px;
}

@media only screen and (max-width: 1300px) {

  .Married .nav-item {
    width: 79px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
  }
}

@media only screen and (max-width: 574px) {

	#home #section0 .fp-tableCell {
		vertical-align: baseline;
		height: 100vh !important;
	}

	#section0 p {
		font-size: 20px;
	}

	#home #section1, #home #bemylove, #home #section2, #home #section3, #home #section4 {
		height: auto;
		padding: 20px 0;
	}

	#home h1 {
	  font-size: 50px;
	  margin: 0;
	}

	#home .flex-container {
		display: block;
	}

	#home .section .flex-container .flex-item, #section4 .flex-item {
		width: 90%;
    margin: 0 auto;
	}

	#home .icon-custom-1, #home .icon-custom-2 {
		font-size: 100px;
	}

	#home .users {
		padding: 0;
	}

	#home .text h2,
	#section4 .text h2 {
		font-size: 30px;
	}

	#home .section-1 .col-2 {
		padding-left: 0;
	}

	.nav-cta .btn-login, .nav-cta .center-single-btn {
		margin-top: 5px;
		margin-bottom: 5px;
	}

	#home .PlayCard .card {
		width: 49% !important;
		margin-right: 0;
		margin: 0 auto;
		margin-bottom: 20px;
	}

}
/* FINE HOME PAGE */

@media screen and (max-width: 1028px) {

	.Site .bg-logo {
		background-image: none;
	}

	#home .white {
		padding-top: 13vh;
	}

	.btn-submit {
		width: 100%;
		/* margin-bottom: 20px */
	}

	.full-padding {
		padding: 0 !important;
	}

	.budget-content {
		border-radius: 6px;
	}

	.speech-bubble-1 {
		margin-left: 10%;
	}

	.content-elms .row .col-lg-1 svg {
		margin-top: 0;
	}

	.General .col-lg-6 .row-content {
		width: 100%;
	}

	.General .container {
		padding: 120px 0 20px 0;
	}

	.margin-top-50 {
		margin-top: 0%;
	}

	.tooltip .tooltiptext {
	  bottom: 100%;
		top: -55px;
	}

	.btn-edit {
    width: 100%;
	}

	.content-elms .row {
		padding-bottom: 20px !important;
	}

	.content-img-modal {
		width: 100%;
	}

	.img-modal {
		width: 100%;
		height: auto;
	}

	.chat-img-bubble {
		width: 100% !important;
	}

	.button-chat {
		right: 10px;
	}

	#chat {
		width: 89%;
	}

	/* #home section {
		display: none;
	} */


	#home .waveWrapper {
		position: absolute;
		top: auto;
	}

	#home .button {
		margin: 0;
	}

	#home .content-section-1 {
		-webkit-flex-wrap: wrap-reverse;
		        flex-wrap: wrap-reverse;
	}

	#home .content-section-1 .col-1 {
		min-width: 100%;
		max-width: 100%;
	}

	#home .content-section-1 .col-2 {
		min-width: 250px;
		margin: 0 auto;
	}

	#home .content-trittico {
			width: 100%;
	}

	#home .content-trittico img {
			width: 90%;
	}

	#home .cta-point-2 {
		display: block;
		margin-top: 20px;
	}

	#home .cta-point-2 .button {
		width: 90%;
		margin: 0 auto;
		margin-bottom: 20px;
	}

	#home .section-3 {
		display: none;
	}

	#home .single-col-minilove-text {
		width: 100%;
	}

	#home .content-minilove-div {
		margin-bottom: 20px;
	}

	#home .waveWrapperFooter {
		display: none;
	}

	#section4 .button {
		width: 90%;
	}

}


@media screen and (max-width: 991px) {

	#home .waveWrapper {
		display: none;
	}

	.Married  .navbar-expand-lg .navbar-toggler {
    display: block;
	}

	.Married nav li {
		width: 100% !important;
    font-size: 18px !important;
	}

	.Message .tooltip .tooltiptext {
		margin-left: -100px;
	}

	.Message .tooltip:hover .tooltiptext::after {
		margin-left: 30px;
	}

	.modal.show-modal {
		overflow: auto;
	}

	.modal-content {
		width: 95% !important;
	}

	.modal-content .content-elms {
		margin-top: 20px;
	}

	#register .container {
		/* padding-right: 0;
		padding-left: 0; */
	}

	#register .row-content {
		width: 95%;
		margin: 0 auto;
		margin-bottom: 20px;
	}

	.modal-card .modal-content {
		top: 90%;
	}

	.modal-card .modal-content h2 {
		font-size: 23px;
	}

	.Guest .modal {
		width: 100%;
	}

	/* COUNTDOWN */
	.countdown-item {
		font-size: 35px !important;
		width: 35px !important;
		height: 35px !important;
	}

	.countdown-item span {
		font-size: 10px;
	}

	.countdown-svg {
		display: none;
	}

	.General h3 {
		font-size: 20px;
	}

	.General .card {
		width: 100% !important;
	}

	.content-final-countdown .content-img-countdown {
		width: 152px;
	}

	.content-final-countdown .content-img-countdown img {
		width: 50px;
	}

}


/* IPAD PRO */
@media screen and (max-width: 1024px) and (max-height: 1366px) {
  #login {
		padding-top: 25vh;
	}
	.pos-ipad {
		text-align: center;
		padding-top: 40px;
	}

	.Married .section-text {
		top: 2px;
	}

}

/* IPAD */
@media screen and (max-width: 1024px) and (max-height: 768px) {
	#login {
		padding-top: 15vh;
	}
	.pos-ipad {
		text-align: center;
		padding-top: 40px;
	}

}

/* iphone 5 */
@media screen and (max-width: 320px) and (max-height: 568px) {
	#login {
		padding-top: 10px;
	}
	.pos-ipad {
		text-align: center;
		padding-top: 30px;
	}

	.nav-brand {
    width: 224px;
	}
}

/* galaxy 5 */
@media screen and (max-width: 360px) and (max-height: 640px) {
	#login {
		padding-top: 20px;
	}

	.pos-ipad {
		text-align: center;
		padding-top: 30px;
	}
}

.be-silver {
	width: 60%;
	margin: 0 auto;
	color: #a73972;
}

.Site .be-silver p {
	text-align: center;
	font-size: 18px !important;
}

.be-silver svg {
	color: #a73972;
}

@media screen and (max-width: 1200px) {
	.be-silver {
		width: 90%;
		margin: 0 auto;
		color: #a73972;
	}

	#login .button {
			width: 100% !important;
			margin-bottom: 20px !important;
	}
}

/* CARS */


/* FINE CARS */


/* MENU SITE */

.nav-site {
  z-index: 9999;
	position: fixed;
	box-shadow:         0px -2px 15px 0px rgba(50, 50, 50, 0.75);
}

.nav-site .bar1, .nav-site .bar2, .nav-site .bar3 {
  width: 35px;
  height: 5px;
  margin: 6px 0;
  transition: 0.4s;
}

.nav-site .change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.nav-site .change .bar2 {opacity: 0;}

.nav-site .change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.nav-site .navbar-toggler {
	display: none;
	cursor: pointer;
	border: 0 !important;
}

.nav-brand {
	font-family: "Comfortaa";
	width: 250px;
	/* margin: 0 auto; */
	font-size: 21px;
}

.content-nav-brand,
#nav-bar {
  display: block;
}

.made-in-italy {
  font-size: 12px;
  float: left;
  margin-top: -21px;
  margin-left: 57px;
  text-align: center;
  width: 100%;
}

#list-menu-site {
	display: none;
	position: fixed;
	top: 70px;
	z-index: 9000;
	height: 100vh;
  overflow: auto;
}

#list-menu-site ul {
	list-style: none;
	width: 100%;
  padding-bottom: 70px;
  margin-left: -20px;
}

#list-menu-site li {
	font-family: "Comfortaa";
	text-align: center;
	padding: 20px;
	font-size: 21px;
}

.nav-site.start,
#list-menu-site.start {
	background-color: #d77eac;
}

.nav-site.run,
#list-menu-site.run  {
	background-color: rgba(167, 57, 114, 1);
}

.list-menu-site-hover-one li:hover {
	background-color: #d77eac;
}

.list-menu-site-hover-two li:hover {
	background-color: rgba(167, 57, 114, 1);
}

#nav-site .menu-1 {
  text-align: center;
  margin-top: 10px;
  padding-top: 10px;
}

#nav-site .menu-2 {
  text-align: center;
  margin-top: 15px;
}

#nav-desktop {
	width: 100%;
  display: none;
}

#nav-desktop ul {
	width: 100%;
	margin: 0 auto;
	display: -webkit-flex;
	display: flex;
	font-size: 14px;
  height: 58px;
  margin-left: -20px;
}

#nav-desktop ul li {
	list-style: none;
  width: 25%;
  text-align: center;
  padding-top: 15px;
  font-size: 18px;
}

#nav-desktop ul .li-brand {
  padding-top: 0;
}

#nav-desktop ul .li-brand:hover {
  background-color: transparent;
}

.nav-desktop-hover-1 li,
.nav-desktop-hover-2 li:hover {
  background-color: #d77eac;
}

.nav-desktop-hover-2 li,
.nav-desktop-hover-1 li:hover {
  background-color: #a73972;
}

#nav-desktop ul li ul {
  display: none;
  margin-left: 0;
  padding: 0;
}

#nav-desktop ul li ul li {
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #d77eac;
  border-bottom: 1px solid #d77eac;
}

#nav-desktop ul .submenu:hover ul {
  display: block;
}

#nav-desktop ul .submenu:hover ul li:hover {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

#nav-desktop .nav-brand {
	font-family: "Comfortaa";
	width: 250px;
	margin: 0 auto;
	font-size: 21px;
}

@media screen and (max-width: 1150px) {
  /* #nav-bar {
    display: block;
  }

  */

  .content-nav-brand {
    padding-top: 10px;
  }
  /*
  #nav-desktop {
    display: none;
  } */
}

/* FINE MENU SITE */

.Site {
  	font-family: "Comfortaa";
}

.Site .cover {
  background-color: white;
  padding: 80px 65px;
  padding-top: 150px;
  background-image: url("/img/sfondo.jpg");
  margin-bottom: 30px;
  font-family: "Comfortaa";
}

.Site .cover h1 {
  color: #a73972;
}

.Site h3,
.Site h4 {
  color: black;
  font-size: 20px;
}

.Site .bg-white {
  background-color: white;
  padding: 20px;
}

.Site .bg-white div,
.Site .bg-white p {
  color: black;
}

.Site li {
  color: black;
  font-size: 20px;
  list-style: none;
  margin-left: -20px;
}

.Site li p, .Site p {
  font-size: 18px;
}

.Site a {
  color: #a73972 !important;
}

.Site a:hover {
  font-weight: bold;
}

/* COOKIES */
.banner-cookie {
  width: 100%;
  background-color: black;
  color: white;
  padding: 10px;
  position: fixed;
  z-index: 99999;
  top: 0;
}

.banner-cookie .close-button {
  margin-left: 12px;
}

/* CARD */
.Site.PlayCard .content-text {
  color: black !important;
}

.PlayCard .card {
  border-radius: 6px;
  height: 300px;
  background-color: #a73972 !important;
  margin-right: 20px;
  cursor: pointer;
  margin-bottom: 150px;
  background-image: url('/img/bemylove_logo.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
  cursor: pointer;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform-origin: center right;
          transform-origin: center right;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.PlayCard .card:hover {
  box-shadow:         0px -2px 15px 0px rgba(50, 50, 50, 0.75);
}

.PlayCard .card p {
  font-family: "Comfortaa";
  font-size: 22px;
  position: absolute;
  bottom: 5px;
  left: 0px;
}

.card.is-flipped {
  -webkit-transform: translateX(-50%) rotateY(-90deg);
          transform: translateX(-50%) rotateY(-90deg);
}

.button-share {
  width: 64px;
  float: left;
  margin-left: 30%;
}

.button-share .SocialMediaShareButton {
  cursor: pointer;
}

.UnSubscribe p,
.UnSubscribe label,
.UnSubscribe textarea,
.UnSubscribe input,
.UnSubscribe h2 {
  color: black;
}

.UnSubscribe label {
  font-weight: bold;
}

.UnSubscribe textarea,
.UnSubscribe input {
  border: 1px solid #a73972;
  border-radius: 6px;
  padding: 5px;
}

.UnSubscribe h2 {
  width: 100%;
  color: #a73972;
  margin-bottom: 70px;
}

.UnSubscribe .text-rsl {
  font-size: 21px;
}

.Site.UnSubscribe .cover {
  padding-top: 90px;
}

.Site.UnSubscribe .cover h1 {
  font-size: 31px;
}

.Site.UnSubscribe .row {
  margin-top: 20px;
}

@media screen and (max-width: 414px) {
	.made-in-italy {
    margin-top: -7px;
    margin-left: 33px;
	}
}

@media screen and (max-width: 900px) {
  .card {
    /* width: 250px !important; */
    width: 90% !important;
    height: 350px !important;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .button-share {
    margin-left: 20%;
  }

}

/* galaxy 5 */
@media screen and (max-width: 360px) and (max-height: 640px) {
	.made-in-italy {
    margin-top: -7px;
    margin-left: 33px;
	}
}

/* MiniLove */

.list-minilove svg {
    color: #a73972;
}

.color-white {
  color: white !important;
}

.minilove {
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  display: block;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 10100px;
          perspective: 10100px;
  margin-top: 50px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

.minilove.minilove-rotating {
  transition: none !important;
  animation: showcase_box3d 15s linear 0s infinite reverse;
  -webkit-transform: rotateX(-20deg) rotateY(15deg);
          transform: rotateX(-20deg) rotateY(15deg);
}

.minilove.minilove-rotating-negative {
  transition: none !important;
  /* animation: showcase_box3d 15s linear 0s infinite reverse; */
  -webkit-transform: rotateX(-20deg) rotateY(-15deg);
          transform: rotateX(-20deg) rotateY(-15deg);

    -webkit-perspective: none;

            perspective: none;

    -webkit-perspective: initial;

            perspective: initial;
}

.cube {
  width: 150px;
  height: 150px;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 4000px;
          perspective: 4000px;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  margin-left: auto;
  margin-right: auto;
  text-indent: -99999px;
}

.cube .cube-face {
    width: 150px;
    height: 150px;
    position: absolute;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    font-weight: bold;
    color: #000;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-transform: uppercase;
    overflow: hidden;
    /* background-color: #e7ad99; */
    background-repeat: no-repeat;
    background-size: 750px 150px;
}

.sect-home .minilove-container .minime {
    margin-top: 0;
}

.cube .cube-face.cube-face-front {
    -webkit-transform: translate3d(0, 0, 74px);
            transform: translate3d(0, 0, 74px);
    background-position: 35% 0;
}

.cube-face .cube-face-front-top {
  /* background-image: url('/img/tmp/settings-large/minime_capelli-001_front.png'); */
  background-size: contain;
  -webkit-transform: translate3d(0, 0, 75px);
          transform: translate3d(0, 0, 75px);
  background-position: 25% 0;
}

.cube-face .cube-face-front-skin {
  /* background-image: url('/img/tmp/settings-large/faccia-cover-pelle1.png'); */
  background-size: contain;
  -webkit-transform: translate3d(0, 0, 74px);
          transform: translate3d(0, 0, 74px);
  background-position: 25% 0;
}

.cube-face .cube-face.cube-face-left {
    background-size: contain;
}

.cube-face .cube-face.cube-face-right {
    background-size: contain;
}

.cube-face .cube-face.cube-face-top {
    background-size: contain;
}

.cube-body .cube-face.cube-face-front {
    background-size: contain !important;
}

.cube-body .cube-face.cube-face-left {
    background-size: contain !important;
}

.cube-body .cube-face.cube-face-right {
    background-size: contain !important;
}

.cube .cube-face.cube-face-back {
    -webkit-transform: rotateY(180deg) translate3d(0, 0, 74px);
            transform: rotateY(180deg) translate3d(0, 0, 74px);
    background-position: 100% 0;
}

.Minilove_3 .cube .cube-face.cube-face-back {
  background-size: contain;
}

.cube .cube-face.cube-face-left {
    -webkit-transform: rotateY(-90deg) translate3d(0, 0, 74px);
            transform: rotateY(-90deg) translate3d(0, 0, 74px);
    background-position: 0 0;
}

.cube .cube-face.cube-face-right {
    -webkit-transform: rotateY(90deg) translate3d(0, 0, 74px);
            transform: rotateY(90deg) translate3d(0, 0, 74px);
    background-position: 65% 0;
}

.cube .cube-face.cube-face-top {
    -webkit-transform: rotateX(90deg) translate3d(0, 0, 74px);
            transform: rotateX(90deg) translate3d(0, 0, 74px);
    background-position: 100% 0;
}

.cube .cube-face.cube-face-bottom {
    -webkit-transform: rotateX(-90deg) translate3d(0, 0, 75px);
            transform: rotateX(-90deg) translate3d(0, 0, 75px);
}
/**/
.man .head,
.man .corpo {
  -webkit-animation-name: spinA;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: spinA;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: spinA;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spinA;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.woman .head,
.woman .corpo {
  -webkit-animation-name: spinB;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: spinB;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: spinB;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spinB;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@-webkit-keyframes spinA {
    from {
        -webkit-transform: rotateY(360deg);
    } to {
        -webkit-transform: rotateY(0deg);
    }
}
@keyframes spinA {
    from {
        -webkit-transform: rotateY(360deg);
                transform: rotateY(360deg);
    } to {
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
    }
}
@-webkit-keyframes spinB {
    from {
        -webkit-transform: rotateY(0deg);
    } to {
        -webkit-transform: rotateY(360deg);
    }
}
@keyframes spinB {
    from {
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
    } to {
        -webkit-transform: rotateY(360deg);
                transform: rotateY(360deg);
    }
}

.Minilove_0 .cube.corpo .cube-face.cube-face-front,
.Minilove_3 .cube-body .cube-face.cube-face-front {
    -webkit-transform: translate3d(15px, 0, 52.5px);
            transform: translate3d(15px, 0, 52.5px);
    background-position: 33.32% 0;
}

.Minilove_0 .cube.corpo .cube-face.cube-face-back,
.Minilove_3 .cube-body .cube-face.cube-face-back {
    -webkit-transform: rotateY(180deg) translate3d(-16px, 0px, 52px);
            transform: rotateY(180deg) translate3d(-16px, 0px, 52px);
    background-position: 100% 0;
}

.Minilove_3 .cube-body .cube-face.cube-face-back {
  background-size: contain;
}

.Minilove_0 .cube.corpo .cube-face.cube-face-left,
.Minilove_3 .cube-body .cube-face.cube-face-left {
    -webkit-transform: rotateY(-90deg) translate3d(0, 0, 35px);
            transform: rotateY(-90deg) translate3d(0, 0, 35px);
    background-position: 0 0;
}

.Minilove_0 .cube.corpo .cube-face.cube-face-right,
.Minilove_3 .cube-body .cube-face.cube-face-right {
    -webkit-transform: rotateY(90deg) translate3d(0, 0, 67px);
            transform: rotateY(90deg) translate3d(0, 0, 67px);
    background-position: 66.665% 0;
}

.cube.corpo .cube-face.cube-face-top,
.cube.cube-body .cube-face.cube-face-top {
    height: 105px;
    -webkit-transform: rotateX(90deg) translate3d(0, 0, 52.5px);
            transform: rotateX(90deg) translate3d(0, 0, 52.5px);
}

.cube.corpo .cube-face.cube-face-bottom,
.cube.cube-body .cube-face.cube-face-bottom {
    height: 105px;
    z-index: 9;
    -webkit-transform: rotateX(-90deg) translate3d(0, 0, 52.5px);
            transform: rotateX(-90deg) translate3d(0, 0, 52.5px);
    display: none;
}

.cube.corpo .cube-face,
.cube.cube-body .cube-face {
    width: 105px;
    height: 140px;
    opacity: 1;
    overflow: hidden;
    background-size: 420px 140px;
}

/* STEP 1 */
.Minilove_1  {
  min-height: 80vh !important;
  background-color: white;
}

.Minilove_1 p {
  color: black !important;
}

.Minilove_1 .form-group {
  width: 250px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.Minilove_1 .button-image {
	color: white;
  font-size: 34px;
  background-color: #d77eac;
  padding: 5px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow:         0px -2px 15px 0px rgba(50, 50, 50, 0.75);
}

.Minilove_1 .inputfile {
  width: 100px;
	height: 100px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	left: 120px;
	cursor: pointer;
}

.Minilove_1 h3 {
  color: black;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Minilove_0 .waitSave,
.Minilove_1 .waitSave {
  width: 100%;
  text-align: center;
  font-size: 21px;
}

.Minilove_2 h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding-top: 20px;
}

.Minilove_1 .centered-input,
.Minilove_2 .centered-input {
  width: 100px;
  margin: 0 auto;
  padding-bottom: 10px;
}

.Minilove_2 {
  padding-top: 85px;
  padding-bottom: 50px;
  background-color: white;
}

.Minilove_2 #preview-1, .Minilove_2 #preview-2 {
  border-radius: 6px;
}

.Minilove_2 .slidecontainer {
  width: 50%; /* Width of the outside container */
  margin: 0 auto;
}

.Minilove_2 .scale, .Minilove_2 .rotate {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: rgba(167, 57, 114, 1);
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

.Minilove_2 .scale::-webkit-slider-thumb, .Minilove_2 .rotate::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #d77eac;
  cursor: pointer;
}

.Minilove_2 .scale::-moz-range-thumb, .Minilove_2 .rotate::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #d77eac;
  cursor: pointer;
}

.Minilove_2 #image-container-0,
.Minilove_2 #image-container-1 {
    display: block;
    height: 300px;
    width: 300px;
    margin: 20px auto;
    overflow: hidden;
    //border: 1px solid #cccccc;
    box-sizing: border-box;
    position: relative;
    cursor: move;
}

.Minilove_2 #drag-image-0,
.Minilove_2 #drag-image-1 {
    left: 0;
    pointer-events: none;
    position: relative;
    top: 0;
    -moz-user-select: none;
}

.Minilove_2 .sagoma {
  background-image: url("/img/sagoma.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
}

.Minilove_2 .message-cut,
.Minilove_3 .message-cut {
  text-align: center;
  font-size: 21px;
}

.Minilove_2 .heart-scale-1 {
  padding-left: 0;
  color: rgb(167, 57, 114) !important;
}

.Minilove_2 .heart-scale-2 {
  padding-right: 0;
  text-align: right;
  font-size: 21px;
  margin-top: -7px;
  color: rgb(167, 57, 114) !important;
}

.Minilove_2 .heart-rotate-1 {
  padding-left: 0;
  color: rgb(167, 57, 114) !important;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: -20px;
}

.Minilove_2 .heart-rotate-2 {
  padding-right: 0;
  text-align: right;
  color: rgb(167, 57, 114) !important;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: -20px;
}


.Minilove_3,
.Minilove_4 {
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: white;
}

.Minilove_3 h3 {
  width: 100%;
  text-align: center;
}

.Minilove_3 .cube-face-front {
  background-repeat: no-repeat;
  background-size: cover;
}

.Minilove_3 .block {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.Minilove_3 .block > div,
.Minilove_3 .list-block > div {
  width: 50px;
  height: 50px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
  background-size: cover;
  cursor: pointer;
}
.Minilove_3 .list-block {
  width: 60px;
}

.Minilove_3 .block > div:hover,
.Minilove_3 .list-block > div:hover {
  box-shadow:         0px -2px 15px 0px rgba(50, 50, 50, 0.75);
}

.Minilove_3 .minilove-rotate-view {
  position: absolute;
  top: 16vh;
  z-index: 99;
  cursor: pointer;
  text-align: center;
}

.Minilove_3 .minilove-rotate-view small {
  color: black;
}

.Minilove_3 .minilove-rotate-view-m-1 {
  left: 380px;
}

@media screen and (max-width: 772px) {
  .Minilove_3 .list-block {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .Minilove_3 .minilove-rotate-view-m-1 {
    left: 82% !important;
  }

  .Minilove_3 .btn-submit {
    width: 60%;
    margin-top: 40px;
  }

}

.btn-minilove-save {
  margin-right: 20px;
}

.btn-minilove-delete {
  width: auto;
  margin-left: 20px;
}

@media screen and (max-width: 1032px) {
  .btn-minilove-save,
  .btn-minilove-delete {
    width: 250px;
    margin: 0 auto;
  }
}

.Minilove_4 h2 {
  width: 100%;
  color: black;
}

.Minilove_4 h3 {
  width: 100%;
  text-align: center;
}

.Minilove_4 button {
  margin-top: 70px;
  font-size: 18px;
  width: 250px;
}

.Minilove_4 .imgOmg {
  width: 350px;
  margin: 0 auto;
	box-shadow:         0px -2px 15px 0px rgba(50, 50, 50, 0.75);
}

.Minilove_4 .imgOmg img {
  width: 350px;
}

@media screen and (max-width: 1032px) {
  .Minilove_4 .imgOmg {
    width: 90%;
    margin: 0 auto;
  }

  .Minilove_4 .imgOmg img {
    width: 100%;
  }
}

/* Survey */
.content-vote svg {
  color: #d77eac;
  margin-right: 15px;
  cursor: pointer;
  font-size: 49px;
}

.content-vote svg:hover {
  color: rgba(167, 57, 114, 1);
}

/* .gradient {
  background: linear-gradient(90deg, #a73972 0%, #d77eac 100%);
} */

.button-cta {
  border: 2px solid white !important;
}

.button-cta:hover {
  background-color: rgb(239, 90, 167) !important;
    border: 2px solid rgb(239, 90, 167) !important;
  opacity: 1 !important;
}

.Blog {
  padding-top: 125px;
}

.Blog p {
  font-family: "Comfortaa";
}

.Blog .card {
  width: 80%;
  margin: 0 auto;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  cursor: pointer;
}

.Blog .title {
  text-align: center;
  color: white !important;
  padding: 20px;
  background-color: #d77eac;
  width: 100%;
  height: 300px;
  opacity: 0.8;
  margin-top: 220px;
  -webkit-animation: moveTitleStart 2.5s;
          animation: moveTitleStart 2.5s;
}

.Blog .title h2 {
  font-size: 1.4rem;
}

.Blog .card:hover .title {
  opacity: 1;
  margin-top: 0px;
  -webkit-animation: moveTitle 2.5s;
          animation: moveTitle 2.5s;
  font-weight: normal
}

@-webkit-keyframes moveTitle {
    0% {
      opacity: 0.8;
      margin-top: 220px;
    }
    100% {
      opacity: 1;
      margin-top: 0px;
    }
}

@keyframes moveTitle {
    0% {
      opacity: 0.8;
      margin-top: 220px;
    }
    100% {
      opacity: 1;
      margin-top: 0px;
    }
}

@-webkit-keyframes moveTitleStart {
    0% {
      opacity: 1;
      margin-top: 0px;
    }
    100% {
      opacity: 0.8;
      margin-top: 220px;
    }
}

@keyframes moveTitleStart {
    0% {
      opacity: 1;
      margin-top: 0px;
    }
    100% {
      opacity: 0.8;
      margin-top: 220px;
    }
}

.Blog .abstract {
  color: white !important;
}

.Article .cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  height: 60vh;
}

.Article .cover h1 {
  color: white;
  text-align: center;
  position: relative;
  z-index: 2;
  margin-top: 10vh;
}

.Article .opacity {
  width: 100%;
  height: 60vh;
  background-color: black;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
}

.Article .content {
  width: 100%;
  margin: 0 auto;
}

.Article hr,
.Article + hr {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  height: 1px;
  background-color: white;
  background-image: -webkit-linear-gradient(left, white, #a73972, white);
}

.Article .content h1,
.Article .content h2,
.Article .content h3,
.Article .content h4,
.Article .content h5,
.Article .content p {
  color: black !important;
}

.Article .time {
  width: 100%;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
  margin-bottom: 60px;
}

.Article .content-page-article {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Article .content-page-article .col-article {
  width: 60%;
  padding: 20px;
}

.Article .content-page-article .col-sponsor {
  width: 20%;
}

.Article .content-page-article .sponsor {
  width: 100%;
  height: 350px;
  background-color: white;
}

.Article + div + hr + .interested h3 {
  color: #a73972;
  font-size: 20px;
  font-weight: normal;
}

.Article + div + hr + .interested li,
.list-blog li {
  color: #a73972;
  font-size: 20px;
  list-style: none;
  margin-left: -20px;
  font-weight: normal;
}

.Article + div + hr + .interested li svg,
.list-blog li svg {
  margin-right: 20px;
}

.Article + div + hr + .interested li a:hover,
.list-blog li a:hover {
  color: #d77eac !important;
}

.Article + div .button {
  padding: 10px;
  border-radius: 2px;
  background-color: #a73972;
  color: white;
  width: 90%;
  opacity: 1;
  transition: 0.3s;
  margin: 20px;
}

.Article + div .button:hover {
  opacity: 0.6;
}

.Article .article-image {
  width: 50%;
  margin: 0 auto;
}

.Article .article-image img {
  width: 100%;
}

.Article .download-app {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
}

@media screen and (max-width: 1028px) {

  .Blog .title {
    height: auto;
  }

  .Article .cover h1 {
    margin-top: -4vh;
    font-size: 1.9rem;
  }

  .Article .content-page-article .col-article,
  .Article .content-page-article .col-sponsor {
    width: 100%;
  }

  .Article .time {
    margin-bottom: 30px;
  }

  .Article .article-image {
    width: 100%;
  }

}


