
/* MENU SITE */

.nav-site {
  z-index: 9999;
	position: fixed;
	-webkit-box-shadow: 0px -2px 15px 0px rgba(50, 50, 50, 0.75);
	-moz-box-shadow:    0px -2px 15px 0px rgba(50, 50, 50, 0.75);
	box-shadow:         0px -2px 15px 0px rgba(50, 50, 50, 0.75);
}

.nav-site .bar1, .nav-site .bar2, .nav-site .bar3 {
  width: 35px;
  height: 5px;
  margin: 6px 0;
  transition: 0.4s;
}

.nav-site .change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.nav-site .change .bar2 {opacity: 0;}

.nav-site .change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.nav-site .navbar-toggler {
	display: none;
	cursor: pointer;
	border: 0 !important;
}

.nav-brand {
	font-family: "Comfortaa";
	width: 250px;
	/* margin: 0 auto; */
	font-size: 21px;
}

.content-nav-brand,
#nav-bar {
  display: block;
}

.made-in-italy {
  font-size: 12px;
  float: left;
  margin-top: -21px;
  margin-left: 57px;
  text-align: center;
  width: 100%;
}

#list-menu-site {
	display: none;
	position: fixed;
	top: 70px;
	z-index: 9000;
	height: 100vh;
  overflow: auto;
}

#list-menu-site ul {
	list-style: none;
	width: 100%;
  padding-bottom: 70px;
  margin-left: -20px;
}

#list-menu-site li {
	font-family: "Comfortaa";
	text-align: center;
	padding: 20px;
	font-size: 21px;
}

.nav-site.start,
#list-menu-site.start {
	background-color: #d77eac;
}

.nav-site.run,
#list-menu-site.run  {
	background-color: rgba(167, 57, 114, 1);
}

.list-menu-site-hover-one li:hover {
	background-color: #d77eac;
}

.list-menu-site-hover-two li:hover {
	background-color: rgba(167, 57, 114, 1);
}

#nav-site .menu-1 {
  text-align: center;
  margin-top: 10px;
  padding-top: 10px;
}

#nav-site .menu-2 {
  text-align: center;
  margin-top: 15px;
}

#nav-desktop {
	width: 100%;
  display: none;
}

#nav-desktop ul {
	width: 100%;
	margin: 0 auto;
	display: flex;
	font-size: 14px;
  height: 58px;
  margin-left: -20px;
}

#nav-desktop ul li {
	list-style: none;
  width: 25%;
  text-align: center;
  padding-top: 15px;
  font-size: 18px;
}

#nav-desktop ul .li-brand {
  padding-top: 0;
}

#nav-desktop ul .li-brand:hover {
  background-color: transparent;
}

.nav-desktop-hover-1 li,
.nav-desktop-hover-2 li:hover {
  background-color: #d77eac;
}

.nav-desktop-hover-2 li,
.nav-desktop-hover-1 li:hover {
  background-color: #a73972;
}

#nav-desktop ul li ul {
  display: none;
  margin-left: 0;
  padding: 0;
}

#nav-desktop ul li ul li {
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #d77eac;
  border-bottom: 1px solid #d77eac;
}

#nav-desktop ul .submenu:hover ul {
  display: block;
}

#nav-desktop ul .submenu:hover ul li:hover {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

#nav-desktop .nav-brand {
	font-family: "Comfortaa";
	width: 250px;
	margin: 0 auto;
	font-size: 21px;
}

@media screen and (max-width: 1150px) {
  /* #nav-bar {
    display: block;
  }

  */

  .content-nav-brand {
    padding-top: 10px;
  }
  /*
  #nav-desktop {
    display: none;
  } */
}

/* FINE MENU SITE */

.Site {
  	font-family: "Comfortaa";
}

.Site .cover {
  background-color: white;
  padding: 80px 65px;
  padding-top: 150px;
  background-image: url("/img/sfondo.jpg");
  margin-bottom: 30px;
  font-family: "Comfortaa";
}

.Site .cover h1 {
  color: #a73972;
}

.Site h3,
.Site h4 {
  color: black;
  font-size: 20px;
}

.Site .bg-white {
  background-color: white;
  padding: 20px;
}

.Site .bg-white div,
.Site .bg-white p {
  color: black;
}

.Site li {
  color: black;
  font-size: 20px;
  list-style: none;
  margin-left: -20px;
}

.Site li p, .Site p {
  font-size: 18px;
}

.Site a {
  color: #a73972 !important;
}

.Site a:hover {
  font-weight: bold;
}

/* COOKIES */
.banner-cookie {
  width: 100%;
  background-color: black;
  color: white;
  padding: 10px;
  position: fixed;
  z-index: 99999;
  top: 0;
}

.banner-cookie .close-button {
  margin-left: 12px;
}

/* CARD */
.Site.PlayCard .content-text {
  color: black !important;
}

.PlayCard .card {
  border-radius: 6px;
  height: 300px;
  background-color: #a73972 !important;
  margin-right: 20px;
  cursor: pointer;
  margin-bottom: 150px;
  background-image: url('/img/bemylove_logo.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
  cursor: pointer;
  transform-style: preserve-3d;
  transform-origin: center right;
  transition: transform 1s;
}

.PlayCard .card:hover {
  -webkit-box-shadow: 0px -2px 15px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow:    0px -2px 15px 0px rgba(50, 50, 50, 0.75);
  box-shadow:         0px -2px 15px 0px rgba(50, 50, 50, 0.75);
}

.PlayCard .card p {
  font-family: "Comfortaa";
  font-size: 22px;
  position: absolute;
  bottom: 5px;
  left: 0px;
}

.card.is-flipped {
  transform: translateX(-50%) rotateY(-90deg);
}

.button-share {
  width: 64px;
  float: left;
  margin-left: 30%;
}

.button-share .SocialMediaShareButton {
  cursor: pointer;
}

.UnSubscribe p,
.UnSubscribe label,
.UnSubscribe textarea,
.UnSubscribe input,
.UnSubscribe h2 {
  color: black;
}

.UnSubscribe label {
  font-weight: bold;
}

.UnSubscribe textarea,
.UnSubscribe input {
  border: 1px solid #a73972;
  border-radius: 6px;
  padding: 5px;
}

.UnSubscribe h2 {
  width: 100%;
  color: #a73972;
  margin-bottom: 70px;
}

.UnSubscribe .text-rsl {
  font-size: 21px;
}

.Site.UnSubscribe .cover {
  padding-top: 90px;
}

.Site.UnSubscribe .cover h1 {
  font-size: 31px;
}

.Site.UnSubscribe .row {
  margin-top: 20px;
}

@media screen and (max-width: 414px) {
	.made-in-italy {
    margin-top: -7px;
    margin-left: 33px;
	}
}

@media screen and (max-width: 900px) {
  .card {
    /* width: 250px !important; */
    width: 90% !important;
    height: 350px !important;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .button-share {
    margin-left: 20%;
  }

}

/* galaxy 5 */
@media screen and (max-width: 360px) and (max-height: 640px) {
	.made-in-italy {
    margin-top: -7px;
    margin-left: 33px;
	}
}

/* MiniLove */

.list-minilove svg {
    color: #a73972;
}

.color-white {
  color: white !important;
}

.minilove {
  transition: transform 0.6s;
  display: block;
  transform-style: preserve-3d;
  perspective: 10100px;
  margin-top: 50px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

.minilove.minilove-rotating {
  transition: none !important;
  animation: showcase_box3d 15s linear 0s infinite reverse;
  transform: rotateX(-20deg) rotateY(15deg);
}

.minilove.minilove-rotating-negative {
  transition: none !important;
  /* animation: showcase_box3d 15s linear 0s infinite reverse; */
  transform: rotateX(-20deg) rotateY(-15deg);

    perspective: initial;
}

.cube {
  width: 150px;
  height: 150px;
  position: relative;
  transform-style: preserve-3d;
  perspective: 4000px;
  transition: transform 0.6s;
  margin-left: auto;
  margin-right: auto;
  text-indent: -99999px;
}

.cube .cube-face {
    width: 150px;
    height: 150px;
    position: absolute;
    transition: transform 0.6s;
    font-weight: bold;
    color: #000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: uppercase;
    overflow: hidden;
    /* background-color: #e7ad99; */
    background-repeat: no-repeat;
    background-size: 750px 150px;
}

.sect-home .minilove-container .minime {
    margin-top: 0;
}

.cube .cube-face.cube-face-front {
    transform: translate3d(0, 0, 74px);
    background-position: 35% 0;
}

.cube-face .cube-face-front-top {
  /* background-image: url('/img/tmp/settings-large/minime_capelli-001_front.png'); */
  background-size: contain;
  transform: translate3d(0, 0, 75px);
  background-position: 25% 0;
}

.cube-face .cube-face-front-skin {
  /* background-image: url('/img/tmp/settings-large/faccia-cover-pelle1.png'); */
  background-size: contain;
  transform: translate3d(0, 0, 74px);
  background-position: 25% 0;
}

.cube-face .cube-face.cube-face-left {
    background-size: contain;
}

.cube-face .cube-face.cube-face-right {
    background-size: contain;
}

.cube-face .cube-face.cube-face-top {
    background-size: contain;
}

.cube-body .cube-face.cube-face-front {
    background-size: contain !important;
}

.cube-body .cube-face.cube-face-left {
    background-size: contain !important;
}

.cube-body .cube-face.cube-face-right {
    background-size: contain !important;
}

.cube .cube-face.cube-face-back {
    transform: rotateY(180deg) translate3d(0, 0, 74px);
    background-position: 100% 0;
}

.Minilove_3 .cube .cube-face.cube-face-back {
  background-size: contain;
}

.cube .cube-face.cube-face-left {
    transform: rotateY(-90deg) translate3d(0, 0, 74px);
    background-position: 0 0;
}

.cube .cube-face.cube-face-right {
    transform: rotateY(90deg) translate3d(0, 0, 74px);
    background-position: 65% 0;
}

.cube .cube-face.cube-face-top {
    transform: rotateX(90deg) translate3d(0, 0, 74px);
    background-position: 100% 0;
}

.cube .cube-face.cube-face-bottom {
    transform: rotateX(-90deg) translate3d(0, 0, 75px);
}
/**/
.man .head,
.man .corpo {
  -webkit-animation-name: spinA;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: spinA;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: spinA;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spinA;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.woman .head,
.woman .corpo {
  -webkit-animation-name: spinB;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: spinB;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: spinB;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spinB;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-ms-keyframes spinA {
    from {
        -ms-transform: rotateY(360deg);
    } to {
        -ms-transform: rotateY(0deg);
    }
}
@-moz-keyframes spinA {
    from {
        -moz-transform: rotateY(360deg);
    } to {
        -moz-transform: rotateY(0deg);
    }
}
@-webkit-keyframes spinA {
    from {
        -webkit-transform: rotateY(360deg);
    } to {
        -webkit-transform: rotateY(0deg);
    }
}
@keyframes spinA {
    from {
        transform: rotateY(360deg);
    } to {
        transform: rotateY(0deg);
    }
}

@-ms-keyframes spinB {
    from {
        -ms-transform: rotateY(0deg);
    } to {
        -ms-transform: rotateY(360deg);
    }
}
@-moz-keyframes spinB {
    from {
        -moz-transform: rotateY(0deg);
    } to {
        -moz-transform: rotateY(360deg);
    }
}
@-webkit-keyframes spinB {
    from {
        -webkit-transform: rotateY(0deg);
    } to {
        -webkit-transform: rotateY(360deg);
    }
}
@keyframes spinB {
    from {
        transform: rotateY(0deg);
    } to {
        transform: rotateY(360deg);
    }
}

.Minilove_0 .cube.corpo .cube-face.cube-face-front,
.Minilove_3 .cube-body .cube-face.cube-face-front {
    transform: translate3d(15px, 0, 52.5px);
    background-position: 33.32% 0;
}

.Minilove_0 .cube.corpo .cube-face.cube-face-back,
.Minilove_3 .cube-body .cube-face.cube-face-back {
    transform: rotateY(180deg) translate3d(-16px, 0px, 52px);
    background-position: 100% 0;
}

.Minilove_3 .cube-body .cube-face.cube-face-back {
  background-size: contain;
}

.Minilove_0 .cube.corpo .cube-face.cube-face-left,
.Minilove_3 .cube-body .cube-face.cube-face-left {
    transform: rotateY(-90deg) translate3d(0, 0, 35px);
    background-position: 0 0;
}

.Minilove_0 .cube.corpo .cube-face.cube-face-right,
.Minilove_3 .cube-body .cube-face.cube-face-right {
    transform: rotateY(90deg) translate3d(0, 0, 67px);
    background-position: 66.665% 0;
}

.cube.corpo .cube-face.cube-face-top,
.cube.cube-body .cube-face.cube-face-top {
    height: 105px;
    transform: rotateX(90deg) translate3d(0, 0, 52.5px);
}

.cube.corpo .cube-face.cube-face-bottom,
.cube.cube-body .cube-face.cube-face-bottom {
    height: 105px;
    z-index: 9;
    transform: rotateX(-90deg) translate3d(0, 0, 52.5px);
    display: none;
}

.cube.corpo .cube-face,
.cube.cube-body .cube-face {
    width: 105px;
    height: 140px;
    opacity: 1;
    overflow: hidden;
    background-size: 420px 140px;
}

/* STEP 1 */
.Minilove_1  {
  min-height: 80vh !important;
  background-color: white;
}

.Minilove_1 p {
  color: black !important;
}

.Minilove_1 .form-group {
  width: 250px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.Minilove_1 .button-image {
	color: white;
  font-size: 34px;
  background-color: #d77eac;
  padding: 5px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -webkit-box-shadow: 0px -2px 15px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow:    0px -2px 15px 0px rgba(50, 50, 50, 0.75);
  box-shadow:         0px -2px 15px 0px rgba(50, 50, 50, 0.75);
}

.Minilove_1 .inputfile {
  width: 100px;
	height: 100px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	left: 120px;
	cursor: pointer;
}

.Minilove_1 h3 {
  color: black;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Minilove_0 .waitSave,
.Minilove_1 .waitSave {
  width: 100%;
  text-align: center;
  font-size: 21px;
}

.Minilove_2 h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding-top: 20px;
}

.Minilove_1 .centered-input,
.Minilove_2 .centered-input {
  width: 100px;
  margin: 0 auto;
  padding-bottom: 10px;
}

.Minilove_2 {
  padding-top: 85px;
  padding-bottom: 50px;
  background-color: white;
}

.Minilove_2 #preview-1, .Minilove_2 #preview-2 {
  border-radius: 6px;
}

.Minilove_2 .slidecontainer {
  width: 50%; /* Width of the outside container */
  margin: 0 auto;
}

.Minilove_2 .scale, .Minilove_2 .rotate {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: rgba(167, 57, 114, 1);
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.Minilove_2 .scale::-webkit-slider-thumb, .Minilove_2 .rotate::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #d77eac;
  cursor: pointer;
}

.Minilove_2 .scale::-moz-range-thumb, .Minilove_2 .rotate::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #d77eac;
  cursor: pointer;
}

.Minilove_2 #image-container-0,
.Minilove_2 #image-container-1 {
    display: block;
    height: 300px;
    width: 300px;
    margin: 20px auto;
    overflow: hidden;
    //border: 1px solid #cccccc;
    box-sizing: border-box;
    position: relative;
    cursor: move;
}

.Minilove_2 #drag-image-0,
.Minilove_2 #drag-image-1 {
    left: 0;
    pointer-events: none;
    position: relative;
    top: 0;
    -moz-user-select: none;
}

.Minilove_2 .sagoma {
  background-image: url("/img/sagoma.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
}

.Minilove_2 .message-cut,
.Minilove_3 .message-cut {
  text-align: center;
  font-size: 21px;
}

.Minilove_2 .heart-scale-1 {
  padding-left: 0;
  color: rgb(167, 57, 114) !important;
}

.Minilove_2 .heart-scale-2 {
  padding-right: 0;
  text-align: right;
  font-size: 21px;
  margin-top: -7px;
  color: rgb(167, 57, 114) !important;
}

.Minilove_2 .heart-rotate-1 {
  padding-left: 0;
  color: rgb(167, 57, 114) !important;
  transform: rotate(-45deg);
  top: -20px;
}

.Minilove_2 .heart-rotate-2 {
  padding-right: 0;
  text-align: right;
  color: rgb(167, 57, 114) !important;
  transform: rotate(45deg);
  top: -20px;
}


.Minilove_3,
.Minilove_4 {
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: white;
}

.Minilove_3 h3 {
  width: 100%;
  text-align: center;
}

.Minilove_3 .cube-face-front {
  background-repeat: no-repeat;
  background-size: cover;
}

.Minilove_3 .block {
  display: flex;
  justify-content: center;
}

.Minilove_3 .block > div,
.Minilove_3 .list-block > div {
  width: 50px;
  height: 50px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
  background-size: cover;
  cursor: pointer;
}
.Minilove_3 .list-block {
  width: 60px;
}

.Minilove_3 .block > div:hover,
.Minilove_3 .list-block > div:hover {
  -webkit-box-shadow: 0px -2px 15px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow:    0px -2px 15px 0px rgba(50, 50, 50, 0.75);
  box-shadow:         0px -2px 15px 0px rgba(50, 50, 50, 0.75);
}

.Minilove_3 .minilove-rotate-view {
  position: absolute;
  top: 16vh;
  z-index: 99;
  cursor: pointer;
  text-align: center;
}

.Minilove_3 .minilove-rotate-view small {
  color: black;
}

.Minilove_3 .minilove-rotate-view-m-1 {
  left: 380px;
}

@media screen and (max-width: 772px) {
  .Minilove_3 .list-block {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .Minilove_3 .minilove-rotate-view-m-1 {
    left: 82% !important;
  }

  .Minilove_3 .btn-submit {
    width: 60%;
    margin-top: 40px;
  }

}

.btn-minilove-save {
  margin-right: 20px;
}

.btn-minilove-delete {
  width: auto;
  margin-left: 20px;
}

@media screen and (max-width: 1032px) {
  .btn-minilove-save,
  .btn-minilove-delete {
    width: 250px;
    margin: 0 auto;
  }
}

.Minilove_4 h2 {
  width: 100%;
  color: black;
}

.Minilove_4 h3 {
  width: 100%;
  text-align: center;
}

.Minilove_4 button {
  margin-top: 70px;
  font-size: 18px;
  width: 250px;
}

.Minilove_4 .imgOmg {
  width: 350px;
  margin: 0 auto;
	-webkit-box-shadow: 0px -2px 15px 0px rgba(50, 50, 50, 0.75);
	-moz-box-shadow:    0px -2px 15px 0px rgba(50, 50, 50, 0.75);
	box-shadow:         0px -2px 15px 0px rgba(50, 50, 50, 0.75);
}

.Minilove_4 .imgOmg img {
  width: 350px;
}

@media screen and (max-width: 1032px) {
  .Minilove_4 .imgOmg {
    width: 90%;
    margin: 0 auto;
  }

  .Minilove_4 .imgOmg img {
    width: 100%;
  }
}

/* Survey */
.content-vote svg {
  color: #d77eac;
  margin-right: 15px;
  cursor: pointer;
  font-size: 49px;
}

.content-vote svg:hover {
  color: rgba(167, 57, 114, 1);
}

/* .gradient {
  background: linear-gradient(90deg, #a73972 0%, #d77eac 100%);
} */

.button-cta {
  border: 2px solid white !important;
}

.button-cta:hover {
  background-color: rgb(239, 90, 167) !important;
    border: 2px solid rgb(239, 90, 167) !important;
  opacity: 1 !important;
}

.Blog {
  padding-top: 125px;
}

.Blog p {
  font-family: "Comfortaa";
}

.Blog .card {
  width: 80%;
  margin: 0 auto;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  cursor: pointer;
}

.Blog .title {
  text-align: center;
  color: white !important;
  padding: 20px;
  background-color: #d77eac;
  width: 100%;
  height: 300px;
  opacity: 0.8;
  margin-top: 220px;
  animation: moveTitleStart 2.5s;
}

.Blog .title h2 {
  font-size: 1.4rem;
}

.Blog .card:hover .title {
  opacity: 1;
  margin-top: 0px;
  animation: moveTitle 2.5s;
  font-weight: normal
}

@keyframes moveTitle {
    0% {
      opacity: 0.8;
      margin-top: 220px;
    }
    100% {
      opacity: 1;
      margin-top: 0px;
    }
}

@keyframes moveTitleStart {
    0% {
      opacity: 1;
      margin-top: 0px;
    }
    100% {
      opacity: 0.8;
      margin-top: 220px;
    }
}

.Blog .abstract {
  color: white !important;
}

.Article .cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  height: 60vh;
}

.Article .cover h1 {
  color: white;
  text-align: center;
  position: relative;
  z-index: 2;
  margin-top: 10vh;
}

.Article .opacity {
  width: 100%;
  height: 60vh;
  background-color: black;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
}

.Article .content {
  width: 100%;
  margin: 0 auto;
}

.Article hr,
.Article + hr {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  height: 1px;
  background-color: white;
  background-image: -webkit-linear-gradient(left, white, #a73972, white);
}

.Article .content h1,
.Article .content h2,
.Article .content h3,
.Article .content h4,
.Article .content h5,
.Article .content p {
  color: black !important;
}

.Article .time {
  width: 100%;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
  margin-bottom: 60px;
}

.Article .content-page-article {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.Article .content-page-article .col-article {
  width: 60%;
  padding: 20px;
}

.Article .content-page-article .col-sponsor {
  width: 20%;
}

.Article .content-page-article .sponsor {
  width: 100%;
  height: 350px;
  background-color: white;
}

.Article + div + hr + .interested h3 {
  color: #a73972;
  font-size: 20px;
  font-weight: normal;
}

.Article + div + hr + .interested li,
.list-blog li {
  color: #a73972;
  font-size: 20px;
  list-style: none;
  margin-left: -20px;
  font-weight: normal;
}

.Article + div + hr + .interested li svg,
.list-blog li svg {
  margin-right: 20px;
}

.Article + div + hr + .interested li a:hover,
.list-blog li a:hover {
  color: #d77eac !important;
}

.Article + div .button {
  padding: 10px;
  border-radius: 2px;
  background-color: #a73972;
  color: white;
  width: 90%;
  opacity: 1;
  transition: 0.3s;
  margin: 20px;
}

.Article + div .button:hover {
  opacity: 0.6;
}

.Article .article-image {
  width: 50%;
  margin: 0 auto;
}

.Article .article-image img {
  width: 100%;
}

.Article .download-app {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media screen and (max-width: 1028px) {

  .Blog .title {
    height: auto;
  }

  .Article .cover h1 {
    margin-top: -4vh;
    font-size: 1.9rem;
  }

  .Article .content-page-article .col-article,
  .Article .content-page-article .col-sponsor {
    width: 100%;
  }

  .Article .time {
    margin-bottom: 30px;
  }

  .Article .article-image {
    width: 100%;
  }

}
